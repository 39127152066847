<template lang="html">
  <v-container pa-0 fluid>
    <v-row dense>
      <v-col cols="12">
        <v-patient-bar @patientLoaded="onPatientLoaded" @patientUnloaded="onPatientUnloaded"></v-patient-bar>
      </v-col>
      <v-col cols="12" v-if="currentPatient">
        <v-tabs-group>
          <template v-slot:tabs>
            <v-tab key="insurance">สิทธิ์การรักษา</v-tab>
            <v-tab key="code">รหัสการรับรองสิทธิ์</v-tab>
          </template>
          <template v-slot:items>
            <v-tab-item key="insurance">
              <v-insurance-register :hn="currentPatient.hn" scope="valid"></v-insurance-register>
            </v-tab-item>
            <v-tab-item key="code">
              <v-patient-code :hn="currentPatient.hn"></v-patient-code>
            </v-tab-item>
          </template>
        </v-tabs-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import patient from '@/modules/helperPatient'
import patientLoader from '@/components/mixins/patientLoader'
import helperItem from '@/modules/helperItem'

export default {
  mixins: [patientLoader],
  data: ()=>({
    currentPatient: undefined,
  }),
  props: {
    hn: {
      type: String,
      default: undefined
    }
  },
  watch: {
    hn: function(newVal) {
      this.loadPatient(this.hn)
    }
  },
}
</script>

<style lang="css" scoped>
</style>
